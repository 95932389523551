import React, { useEffect } from "react";
import { redirect, useParams } from "react-router-dom";
import TestQuestion from "../components/TestQuestion";
import TestResults from "../components/TestResults";
import {
  PatientIDs,
  ResponseCategory,
  TestPatientAssignment,
} from "../testConfiguration";
import { useTestAnswersContext } from "../components/TestAnswersContext";
import RadiologyFooter from "../components/RadiologyFooter";
import Circles from "../assets/RECIP_circles.png";

function TestPage(): React.ReactElement {
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [currentSUVMean, setCurrentSUVMean] = React.useState(10);
  const [showResult, setShowResult] = React.useState(false);
  const [currentTestAnswers, setCurrentTestAnswers] = React.useState<
    Array<ResponseCategory | undefined>
  >([]);
  const { uploadTestAnswers } = useTestAnswersContext();

  const { testId } = useParams();

  const preloadLinks = React.useMemo(() => {
    const links: React.ReactElement[] = [];
    if (!testId) {
      return links;
    }
    const testPatients = TestPatientAssignment.get(parseInt(testId));
    testPatients?.forEach((patientIndex) => {
      const patientId = PatientIDs[patientIndex];
      const paddedPatientId = patientId.toString().padStart(3, "0");
      ["Before", "After"].forEach((time) => {
        [5, 10, 15, 20].forEach((suvMean) => {
          links.push(
            <link
              key={`${paddedPatientId}-${time}-${suvMean}`}
              rel="preload"
              href={`${process.env.PUBLIC_URL}/img/Patient${paddedPatientId}-${time}-${suvMean}.PNG`}
              as="image"
            />,
          );
        });
      });
    });
    return links;
  }, [testId]);

  if (!testId) {
    alert("Invalid test id");
    redirect("/");
    return <></>;
  }

  function onAnswer(newAnswer: ResponseCategory) {
    const newCurrentTestAnswers = Array.from(currentTestAnswers);
    newCurrentTestAnswers[currentQuestion] = newAnswer;
    setCurrentTestAnswers(newCurrentTestAnswers);
  }

  return (
    <div className="test-page-container">
      {preloadLinks}
      {showResult ? (
        <TestResults
          testId={parseInt(testId)}
          currentTestAnswers={currentTestAnswers}
        />
      ) : (
        <TestQuestion
          testId={parseInt(testId)}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          currentQuestionAnswer={currentTestAnswers[currentQuestion]}
          onAnswer={onAnswer}
          onShowResult={() => {
            uploadTestAnswers(parseInt(testId), currentTestAnswers);
            setShowResult(true);
          }}
          currentSUVMean={currentSUVMean}
          setCurrentSUVMean={setCurrentSUVMean}
        />
      )}
      <RadiologyFooter />
    </div>
  );
}

export default TestPage;

import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  PatientIDs,
  TestPatientAssignment,
  questionsPerTest,
} from "../testConfiguration";
import StudyQuestionnaire from "../components/StudyQuestionnaire";
import { Auth } from "aws-amplify";
import { useTestAnswersContext } from "../components/TestAnswersContext";

import RadiologyFooter from "../components/RadiologyFooter";
import useAuth from "../components/useAuth";
import Circles from "../assets/RECIP_circles.png";
import CirclesHuman from "../assets/RECIP_circles_human.png";

// Generate preload links - first set of images for each test
const preloadLinks: React.ReactElement[] = [];
TestPatientAssignment.forEach((patients) => {
  const firstPatientId = PatientIDs[patients[0]];
  const paddedFirstPatientId = firstPatientId.toString().padStart(3, "0");
  ["Before", "After"].forEach((time) => {
    preloadLinks.push(
      <link
        key={`${paddedFirstPatientId}-${time}`}
        rel="preload"
        href={`${process.env.PUBLIC_URL}/img/Patient${paddedFirstPatientId}-${time}-10.PNG`}
        as="image"
      />,
    );
  });
});

function EducationalPlatformPage() {
  const [showStudyQuestionnaire, setShowStudyQuestionnaire] =
    React.useState(false);

  const { answers, correctAnswersForTestId } = useTestAnswersContext();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const testCompletion = React.useMemo(() => {
    return Array.from(TestPatientAssignment.values()).map((patientIndices) => {
      for (let patientIndex of patientIndices) {
        if (answers[patientIndex] === undefined) {
          return false;
        }
      }
      return true;
    });
  }, [answers]);

  React.useEffect(() => {
    if (isAuthenticated) fetchUserAttributes();
  }, []);

  async function fetchUserAttributes() {
    const user = await Auth.currentAuthenticatedUser();

    const showStudyQuestionnaireCondition =
      user.attributes.hasOwnProperty("custom:study_participant") &&
      !user.attributes.hasOwnProperty("custom:country");
    setShowStudyQuestionnaire(showStudyQuestionnaireCondition);
  }

  const onClick = (testId: number) => {
    if (!isAuthenticated) navigate("/login");
    else navigate(`/test/${testId}`);
  };

  return (
    <>
      {/* Preload links and modals */}
      {preloadLinks}
      <StudyQuestionnaire
        show={showStudyQuestionnaire}
        onHide={() => {
          setShowStudyQuestionnaire(false);
        }}
      />
      <Container fluid className="text-white educational-page-header">
        <Container
          fluid="md"
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        >
          <Row
            className="d-flex align-items-center"
            style={{ position: "relative" }}
          >
            <Col md={6} className="header-container">
              <img
                className="background-circles-human"
                src={CirclesHuman}
                alt=""
                style={{ zIndex: 1 }}
              />
            </Col>
            <Col md={6} className="text-section">
              <h1
                className="text-center display-3 mb-4"
                style={{ fontWeight: 500 }}
              >
                Educational Platform
              </h1>
              <p className="subtitle-paragraph">
                This platform was designed to offer free training to nuclear
                medicine physicians across the world who intend to use RECIP in
                their daily practice or in clinical trials.
              </p>
              <p className="subtitle-paragraph">
                The goal of the training is to increase physicians' confidence
                in using Practical RECIP for treatment response evaluation and
                ensure delivery of the highest quality health care for men with
                prostate cancer.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <div style={{ paddingTop: "4rem" }}>
        <h1
          className="text-center display-4 mb-5"
          style={{ fontWeight: 500, fontSize: "2.5rem" }}
        >
          Select a test to begin
        </h1>
        <Row className="d-flex justify-content-center">
          {Array.from(TestPatientAssignment.keys()).map((testId) => (
            <Col
              key={testId}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={2}
              className="d-flex justify-content-center mb-4"
            >
              <div className="test-card">
                <div className="test-card-body text-center">
                  <h5 className="test-title mb-3" style={{ fontWeight: 500 }}>
                    Test {testId}
                  </h5>
                  <p className="test-description">
                    {testCompletion[testId - 1]
                      ? `Latest result: ${correctAnswersForTestId(testId)}/${questionsPerTest(testId)}`
                      : "New Test! Start now to check your knowledge."}
                  </p>
                  <Button
                    className={`btn-test-${testCompletion[testId - 1] ? "retake" : "start"}`}
                    size="lg"
                    onClick={() => onClick(testId)}
                  >
                    {testCompletion[testId - 1] ? "Retake test" : "Start test"}
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <RadiologyFooter />
    </>
  );
}

export default EducationalPlatformPage;

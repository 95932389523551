import rawTestResponses from "./rawTestResponses.json";

export const ResponseCategories = [
  "Complete Response",
  "Partial Response",
  "Progressive Disease",
  "Stable Disease",
];
export type ResponseCategory = (typeof ResponseCategories)[number];

export function serialiseResponseCategory(
  responceCategory: ResponseCategory | undefined,
): string {
  switch (responceCategory) {
    case "Complete Response":
      return "0";
    case "Partial Response":
      return "1";
    case "Progressive Disease":
      return "2";
    case "Stable Disease":
      return "3";
    default:
      return "-";
  }
}

export function deserialiseResponseCategory(
  responseCategory: string,
): ResponseCategory | undefined {
  switch (responseCategory) {
    case "0":
      return "Complete Response";
    case "1":
      return "Partial Response";
    case "2":
      return "Progressive Disease";
    case "3":
      return "Stable Disease";
    case "-":
    default:
      return undefined;
  }
}

export const Radiotracers = [
  "\u2076\u2078Ga-PSMA-11 PET",
  "\u2071\u2078F-rhPSMA-7.3 PET",
];
export type Radiotracer = (typeof Radiotracers)[number];

export interface Response {
  baselinePSMAVolume: number;
  followupPSMAVolume: number;
  category: ResponseCategory;
  radiotracer: Radiotracer;
}

interface ResponseWithId extends Response {
  id: number;
}

export const TestResponses: Map<number, Response> = new Map(
  rawTestResponses.map((testResponse: ResponseWithId) => {
    const { id, ...rest } = testResponse;
    return [id, rest];
  }),
);

// List of all patient IDs, in the order they are stored in the DB
export const PatientIDs: number[] = [
  4, 5, 7, 9, 10, 11, 12, 13, 14, 15, 17, 18, 20, 22, 24, 26, 27, 29, 30, 32,
  34, 35, 36, 37, 38, 40, 41, 44, 45, 46, 48, 50, 51, 53, 54, 55, 56, 57, 58,
  59, 61, 65, 66, 68, 70, 75, 77, 78, 79, 87, 89, 91, 92, 93, 99, 100, 103, 105,
  106, 108, 109, 114, 118, 127, 128, 131, 132, 133, 135, 138, 140, 143, 147,
  148, 150, 154, 156, 157, 160, 161, 163, 173, 175, 178, 180, 181, 185, 186,
  187, 189, 190, 191, 193, 194, 198, 200, 201, 204, 205, 206,
];

export const TestPatientAssignment: Map<number, Array<number>> = new Map([
  [1, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]],
  [
    2,
    [
      20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
      38, 39,
    ],
  ],
  [
    3,
    [
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
      58, 59,
    ],
  ],
  [
    4,
    [
      60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
      78, 79,
    ],
  ],
  [
    5,
    [
      80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
      98, 99,
    ],
  ],
]);

export const TestPatientAssignment1: Map<number, Array<number>> = new Map([
  [1, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]],
  [
    2,
    [
      20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
      38, 39,
    ],
  ],
]);

export const TestPatientAssignment2: Map<number, Array<number>> = new Map([
  [
    3,
    [
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
      58, 59,
    ],
  ],
  [
    4,
    [
      60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
      78, 79,
    ],
  ],
  [
    5,
    [
      80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
      98, 99,
    ],
  ],
]);

export const NumberOfTests = TestPatientAssignment.size;

export const NumberOfPatients = PatientIDs.length;

export function questionsPerTest(testId: number): number {
  const patientIndices = TestPatientAssignment.get(testId);
  if (!patientIndices) {
    console.warn("Test id not recognised");
    return 0;
  }
  return patientIndices.length;
}

import { Button, Container, Form, Image, Nav, Navbar } from "react-bootstrap";
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom";

import RECIPLogoRGB from "../assets/RECIP_Logo_RGB.svg";
import { Auth } from "aws-amplify";
import useAuthRoles from "./useAuthRoles";
import useAuth from "./useAuth";
import { useRECIPOnlineContext } from "../components/RECIPOnlineContext";
import RECIPOnlineStudyFlyerModal from "../components/RECIPOnlineFlyerModal";

import useEducationalPlatformClick from "./useEducationalPlatformClick";
import React, { useEffect } from "react";
import Circles from "../assets/RECIP_circles.png";

function Layout(): React.ReactElement {
  const { isAdmin } = useAuthRoles();
  const { isAuthenticated } = useAuth();
  const { showFlyer, setShowFlyer } = useRECIPOnlineContext();

  const navigate = useNavigate();
  const onEducationalPlatformClick = useEducationalPlatformClick();

  const isTestPage = useMatch({ path: "/test/*" }) != null;
  const isLoginPage = useMatch({ path: "/login" }) != null;

  useEffect(() => {
    console.log(isTestPage);
    if (isTestPage) document.body.classList.add("test-page");
    else document.body.classList.remove("test-page");

    if (isLoginPage) document.body.classList.add("login-page");
    else document.body.classList.remove("login-page");
  }, [isTestPage, isLoginPage]);

  return (
    <>
      <RECIPOnlineStudyFlyerModal
        show={showFlyer}
        onHide={() => {
          setShowFlyer(false);
        }}
      />
      <img className="background-circles" src={Circles} alt="" />
      <Navbar expand="lg" className="navbar-light navbar">
        <Container fluid="lg">
        <a href="https://recip-criteria.com" className="navbar-brand" target="_blank" rel="noopener noreferrer">
      <Image
        fluid
        style={{ height: 40, width: "auto" }}
        src={RECIPLogoRGB}
        alt="RECIP Project logo"
        className="d-inline-block align-top"
      />
    </a>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="text-black ms-auto">
            <Nav className="ms-auto me-2">
              {isAdmin && (
                <Link to="/admin" className="nav-link text-black">
                  Admin console
                </Link>
              )}
              {!isAuthenticated && (
                <Link to="/login" className="nav-link text-black">
                  Sign in
                </Link>
              )}
              {isAuthenticated && (
                <Nav.Link
                  className="text-black"
                  style={{ color: "red" }}
                  onClick={async () => {
                    await Auth.signOut({ global: true });
                    navigate("/");
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                  }}
                >
                  Sign out
                </Nav.Link>
              )}
            </Nav>
            <Form className="d-flex">
              <Button className="btn-blue" onClick={onEducationalPlatformClick}>
                Educational Platform
              </Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
      <Container className="no-padding footer-container">
        <footer className="d-flex flex-row justify-content-center py-3">
          <span className="text-center text-black">
            <small>
              
              © Copyright 2024, RECIP Criteria
            </small>
          </span>
        </footer>
      </Container>
    </>
  );
}

export default Layout;

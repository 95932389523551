import { Row, Col, Image, Container, Card } from "react-bootstrap";
import "./RECIPOnlineWaiver.css";
import JHMLogo from "../assets/JHM-logo.svg";

export default function RECIPOnlineWaiver() {
  return (
    <Container fluid className="RECIPOnlineWaiver waiver-card mt-5">
      {/* Approval and Principal Investigator Section */}
      <div className="p-1 ">
        <Row className="align-items-center">
          <Col
            md="6"
            className="d-flex flex-column flex-md-row align-items-center"
          >
            <Image
              fluid
              src={JHMLogo}
              style={{ height: "40pt", marginRight: "1rem" }}
            />
            <p className="text-muted m-0 text-center text-md-left">
              Approved August 7, 2023
            </p>
          </Col>
          <Col md="6" className="text-center text-md-right text-muted">
            <p className="m-0">
              Date: July 25, 2023 <br />
              Principal Investigator: Andrei Gafita MD <br />
              Application No.: IRB00393616
            </p>
          </Col>
        </Row>
      </div>

      {/* Waiver Title Section */}
      <div className="p-1 mt-4 ">
        <h5 className="text-center">WAIVER OF DOCUMENTATION OF CONSENT</h5>
      </div>

      {/* Protocol Title Section */}
      <div className="p-1 mt-4 ">
        <h6>Protocol Title</h6>
        <p>
          Prospective evaluation to evaluate the adoption and impact of the
          RECIP educational platform for imaging-based response evaluation
          criteria in prostate cancer.
        </p>
      </div>

      {/* Key Information Section */}
      <div className="p-1 mt-4 ">
        <h6>Key Information</h6>
        <p>
          The purpose of this research study is to evaluate the adoption and
          impact of RECIP online educational platform for imaging-based response
          evaluation criteria in prostate cancer. RECIP is a novel standardized
          framework to evaluate drug efficacy using PSMA-PET/CT imaging in
          metastatic castration-resistant prostate cancer. The RECIP online
          platform provides free training for clinicians or scientists who
          intend to use RECIP in their clinical practice or medical research.
        </p>
        <p>
          Participants of this study will fill out a set of 5 online surveys.
          Each survey consists of 20 pairs of baseline and follow-up PSMA-PET
          maximum intensity projection (MIP) images. One survey takes about 3
          minutes to be completed. After completing this informed consent
          process and agree to enroll in the study, you will be asked to
          complete the first survey right away. The next surveys can be
          completed within 4 weeks. There will be no personally-identifying
          information collected as part of this study.
        </p>
        <p>
          The greatest risks of this study is that you may get tired or bored
          when you are completing surveys.
        </p>
      </div>

      {/* Study Introduction Section */}
      <div className="p-1 mt-4 ">
        <h6>Study Introduction</h6>
        <p>
          Thank you for your interest in this research study that evaluates the
          adoption and impact of RECIP online educational platform for
          evaluating drug efficacy in metastatic prostate cancer. This study
          will involve filling out a set of 5 online surveys. The greatest risks
          of this study include emotional discomfort when answering some
          questions.
        </p>
      </div>

      {/* Purpose of This Study Section */}
      <div className="p-1 mt-4 ">
        <h6>Purpose of This Study</h6>
        <p>
          You are being asked to take part in a research study. The purpose of
          this study is to i) understand the adoption and impact of RECIP online
          educational platform and ii) to learn more about the readiness of
          RECIP criteria to be used in clinical practice or medical research to
          evaluate drug efficacy in patients with metastatic prostate cancer.
          Response Evaluation Criteria in PSMA-PET/CT (RECIP 1.0) is the first
          standardized framework for response evaluation in prostate cancer
          using PSMA PET imaging. RECIP combines changes in total tumor volume
          and the status of new lesions on the follow-up scan. Current research
          showed that drug efficacy can be evaluated by nuclear medicine
          specialists by using RECIP criteria. However, the study included
          physicians with high experience in reading PSMA-PET/CT scans. There is
          a lack of evidence on whether RECIP criteria can be used widely, even
          by physicians who do not have experience in PSMA-PET/CT.
        </p>
        <p>
          The researchers conducting this study do not advocate or promote the
          use of RECIP criteria. The aim of this research is to evaluate the
          adoption and impact of RECIP online educational platform and the
          readiness of using RECIP criteria to evaluate drug efficacy in
          patients with metastatic prostate cancer.
        </p>
        <p>
          This study is designed for individuals from the medical community. You
          are invited to participate if you are a nuclear medicine, radiologist,
          urologist, medical oncologist, or a prostate cancer researcher.
        </p>
        <p>
          This study will involve filling out a set of up to 5 online surveys,
          and you can opt out or reschedule at anytime. After completing this
          initial informed consent process and agree to enroll in the study, you
          will be asked to complete the first survey right away. The next
          surveys can be completed within 4 weeks.
        </p>
      </div>

      {/* Inclusion Criteria Section */}
      <div className="p-1 mt-4 ">
        <h6>Inclusion Criteria</h6>
        <ol>
          <li>You are at least 18 years old.</li>
          <li>You can read and write English.</li>
          <li>
            You are willing to complete the baseline and post-session surveys.
          </li>
        </ol>
        <p>
          Participation in this study involves filling out a sequence of 5
          online surveys (as outlined above) that will take approximatively 3
          minutes each. You are NOT required to complete all surveys in one
          sitting. If you meet study inclusion criteria and agree to
          participate, you will be asked to complete the baseline and one
          post-session survey today.
        </p>
      </div>

      {/* What Kind of Information Section */}
      <div className="p-1 mt-4 ">
        <h6>What kind of information will I be providing?</h6>
        <p>
          In the baseline survey, you will be asked questions about the country
          where you currently work or study, career level, medical specialty,
          level of experience in the field, your confidence in using RECIP
          criteria for evaluation of drug efficacy, and whether you intend to
          use RECIP criteria in your clinical practice or research. At the
          completion of each session, you will be asked to provide information
          about your confidence in using RECIP criteria, about which PET
          intensity you preferred, and provide feedback if desired.
        </p>
      </div>

      {/* Risks/Discomforts Section */}
      <div className="p-1 mt-4 ">
        <h6>Risks/Discomforts</h6>
        <p>
          You may get tired or bored when you are completing surveys. You do not
          have to answer any question you do not want to answer.
        </p>
      </div>

      {/* Confidentiality Section */}
      <div className="p-1 mt-4 ">
        <h6>Is my participation confidential?</h6>
        <p>
          There will be no personally-identifying information collected as part
          of this study. Your anonymous responses will be seen and analyzed by
          Johns Hopkins staff or representatives. The study data will be hosted
          on a secure cloud service website (www.aws.amazon.com/cognito/).
          Amazon Cognito is a reputable and secure platform for data online
          storage. To further protect the confidentiality of participants, the
          results of this study will be presented in aggregate.
        </p>
      </div>

      {/* Benefits Section */}
      <div className="p-1 mt-4 ">
        <h6>Benefits</h6>
        <p>
          There is no direct benefit to you from being in this study. Responses
          from many different people and your input could materially evolve our
          collective scientific understanding of using RECIP criteria for
          evaluation of drug efficacy in patients with prostate cancer.
        </p>
      </div>

      {/* Voluntary Participation Section */}
      <div className="p-1 mt-4 ">
        <h6>Voluntary Participation</h6>
        <p>
          You do not have to agree to be in this study. You can agree to be in
          this study now and change your mind later. If you wish to stop, please
          tell us right away. Leaving this study will not stop you from getting
          regular medical care.
        </p>
      </div>

      {/* Compensation Section */}
      <div className="p-1 mt-4 ">
        <h6>Compensation</h6>
        <p>
          There is no compensation in any form related to participation in this
          study.
        </p>
      </div>

      {/* Future Research Section */}
      <div className="p-1 mt-4 ">
        <h6>Identifiable Information in Future Research</h6>
        <p>
          We may use the information collected through this study for future
          research including research with external collaborators. Generally,
          there is no risk for loss of confidentiality since we do not collect
          any personally-identifying information from you.
        </p>
      </div>

      {/* HIPAA Disclosure Section */}
      <div className="p-1 mt-4 ">
        <h6>HIPAA DISCLOSURE</h6>
        <p>
          We will collect non-identifiable information about you in this study.
          People at Johns Hopkins who are involved in the study or who need to
          make sure the study is being done correctly will see the information.
        </p>
        <p>
          Your Authorization for the collection, use, and sharing of your
          information does not expire. We will continue to collect information
          about you until the end of the study unless you tell us that you have
          changed your mind. If you change your mind and do not want your
          information to be used for the study, you must contact the Principal
          Investigator (Dr. Gafita) by using the contact information provided in
          this document. Your cancellation will not affect information already
          collected in the study, or information that has already been shared
          with others before you cancelled your authorization.
        </p>
        <p>
          We try to make sure that everyone who needs to see your information
          uses it only for the study and keeps it confidential - but, we cannot
          guarantee this.
        </p>
      </div>

      {/* Contact Information Section */}
      <div className="p-1 mt-4 ">
        <h6>Contact Information</h6>
        <p>
          If you have any questions about this study, please feel free to
          contact Dr. Andrei Gafita at 410-502-8177 or{" "}
          <a href="mailto:agafita1@jhmi.edu">agafita1@jhmi.edu</a>.
        </p>
        <p>
          For questions about your rights as a research participant, contact the
          IRB at 410-502-2092 or{" "}
          <a href="mailto:jhmeirb@jhmi.edu">jhmeirb@jhmi.edu</a>.
        </p>
      </div>

      {/* Start Section */}
      <div className="p-1 mt-4 ">
        <h6>How do I start?</h6>
        <p>
          You can begin the study by clicking "Create account and begin study"
          at the bottom of this page. The initial questionnaire and the first
          post-session survey will take approximately 5 minutes and must be
          completed in one sitting.
        </p>
        <p>
          It is important that you answer each question honestly and seriously.
          If you are not ready to complete the survey now, please return to this
          page at a time that is convenient for you.
        </p>
        <p>
          You can exit the survey at any time by clicking the link in the upper
          right corner of your screen. If you exit the survey early, your
          responses will not be used.
        </p>
        <p>
          During the survey, please do not hit the "back" button on your
          internet browser as it may erase your answers or prematurely terminate
          your session.
        </p>
        <p>By clicking 'Begin study below, you affirm that:</p>
        <ul>
          <li>You have read the information above,</li>
          <li>You voluntarily agree to participate,</li>
          <li>You are at least 18 years old,</li>
          <li>You read and write English,</li>
          <li>
            You are willing to complete baseline and post-session surveys.
          </li>
        </ul>
      </div>
    </Container>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import EducationalPlatformPage from "./pages/EducationalPlatformPage";
import TestPage from "./pages/TestPage";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/Layout";
import "./AWSConfig";
import "@aws-amplify/ui-react/styles.css";
import RequireAuth from "./components/RequireAuth";
import LoginPage from "./pages/LoginPage";
import { Authenticator } from "@aws-amplify/ui-react";
import TermsAndConditions from "./pages/TermsAndConditions";
import { TestAnswersContextProvider } from "./components/TestAnswersContext";
import AdminConsolePage from "./pages/AdminConsolePage";
import RequireRole from "./components/RequireRole";

import "./index.css";
import { RECIPOnlineContextProvider } from "./components/RECIPOnlineContext";
import RECIPOnlineStudyPage from "./pages/RECIPOnlineStudyPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <RECIPOnlineContextProvider>
        <TestAnswersContextProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              <Route element={<Layout />}>
                <Route
                  path="/recip"
                  element={
                    <RequireAuth>
                      <RECIPOnlineStudyPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/terms_and_conditions"
                  element={<TermsAndConditions />}
                />
                <Route
                  path="/login"
                  element={<LoginPage initialState="signIn" />}
                />
                <Route
                  path="/signup"
                  element={<LoginPage initialState="signUp" />}
                />
                <Route index element={<EducationalPlatformPage />} />
                <Route path="test/:testId" element={<TestPage />} />
                <Route
                  path="/admin"
                  element={
                    <RequireAuth>
                      <RequireRole>
                        <AdminConsolePage />
                      </RequireRole>
                    </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </TestAnswersContextProvider>
      </RECIPOnlineContextProvider>
    </Authenticator.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { Button, Form, ListGroup, ProgressBar } from "react-bootstrap";
import { redirect } from "react-router-dom";
import {
    ResponseCategory,
    ResponseCategories,
    TestResponses,
    TestPatientAssignment,
    PatientIDs,
} from "../testConfiguration";
import TestFigure from "./TestFigure";

import "./TestQuestion.css";

type TestQuestionProps = {
    testId: number;
    currentQuestion: number;
    setCurrentQuestion: Function;
    currentQuestionAnswer: ResponseCategory | undefined;
    onAnswer: Function;
    onShowResult: Function;
    currentSUVMean: number;
    setCurrentSUVMean: Function;
};

function TestQuestion({
                          testId,
                          currentQuestion,
                          setCurrentQuestion,
                          currentQuestionAnswer,
                          onAnswer,
                          onShowResult,
                          currentSUVMean,
                          setCurrentSUVMean,
                      }: TestQuestionProps): React.ReactElement {
    const testPatients = TestPatientAssignment.get(testId);
    if (!testPatients) {
        console.error("Test definition not found");
        redirect("/");
        return <></>;
    }
    const currentPatientIndex = testPatients[currentQuestion];
    const currentPatientId = PatientIDs[currentPatientIndex];
    const correctResponse = TestResponses.get(currentPatientId)!;

    function currentPatientImageSrc(time: "Before" | "After"): string {
        const paddedPatientId = currentPatientId.toString().padStart(3, "0");
        return `${process.env.PUBLIC_URL}/img/Patient${paddedPatientId}-${time}-${currentSUVMean}.PNG`;
    }

    function categoryExplanation(category: ResponseCategory): string {
        switch (category) {
            case "Complete Response":
                return "Disappearance of all tumor lesions";
            case "Partial Response":
                return "≥30% reduction in PSMA-VOL";
            case "Progressive Disease":
                return "≥20% increase in PSMA-VOL";
            case "Stable Disease":
                return "<30% reduction or <20% increase in PSMA-VOL";
            default:
                return "";
        }
    }

    return (
        <div className="test-question-container">
            {/* Header with progress bar */}
            <div className="test-question-header">
                <h5>Test {testId}</h5>
                <ProgressBar
                    now={((currentQuestion + 1) / testPatients.length) * 100}
                    label={`Case ${currentQuestion + 1} of ${testPatients.length}`}
                    className="test-question-progress"
                />
            </div>

            {/* Images with vertical scale bar */}
            <div className="test-question-images-with-scale">
                <div className="vertical-scale-bar">
                    <p>{currentSUVMean}</p>
                    <div className="scale-bar">
                        <div className="scale-label">{currentSUVMean / 2}</div>
                    </div>
                    <p>0</p>
                </div>
                <div className="test-question-images">
                    <div className="test-question-image-wrapper">
                        <TestFigure
                            src={currentPatientImageSrc("Before")}
                            caption="Baseline"
                            radiotracer={correctResponse.radiotracer}
                        />
                    </div>
                    <div className="test-question-image-wrapper">
                        <TestFigure
                            src={currentPatientImageSrc("After")}
                            caption="Follow-up"
                            radiotracer={correctResponse.radiotracer}
                        />
                    </div>
                </div>
            </div>

            {/* SUVMean slider */}
            <div className="test-question-slider">
                <label htmlFor="suvmean-slider">PET Window Intensity</label>
                <Form.Range
                    id="suvmean-slider"
                    value={currentSUVMean}
                    min={5}
                    max={20}
                    step={5}
                    onChange={(e) => {
                        setCurrentSUVMean(parseInt(e.target.value));
                    }}
                    className="minimalist-range-slider"
                />
                <div className="test-question-slider-labels">
                    <span>5</span>
                    <span>10</span>
                    <span>15</span>
                    <span>20</span>
                </div>
            </div>

            {/* Question */}
            <div className="test-question-prompt">
                <p>
                    Please categorize changes in PSMA-positive total tumor volume
                    (PSMA-VOL) by RECIP criteria:
                </p>
            </div>

            {/* Answer options */}
            <ListGroup className="test-question-answers">
                {ResponseCategories.map((category: ResponseCategory) => {
                    let variant = undefined;
                    if (currentQuestionAnswer !== undefined) {
                        if (category === correctResponse.category) {
                            variant = "success";
                        } else if (category === currentQuestionAnswer) {
                            variant = "danger";
                        }
                    }
                    return (
                        <ListGroup.Item
                            key={`Answers-${category.replace(" ", "-")}`}
                            action
                            onClick={() => {
                                onAnswer(category);
                            }}
                            variant={variant}
                            className="minimalist-list-item"
                            style={{
                                pointerEvents:
                                    currentQuestionAnswer !== undefined ? "none" : "auto",
                            }}
                        >
                            <b>{category}</b> ({categoryExplanation(category)})
                            {currentQuestionAnswer !== undefined &&
                                category === currentQuestionAnswer && (
                                    <div className="test-question-feedback">
                                        Quantitative changes in PSMA-VOL calculated using
                                        semi-automatic tumor segmentation software are{" "}
                                        <b>
                                            {correctResponse.followupPSMAVolume -
                                            correctResponse.baselinePSMAVolume >
                                            0
                                                ? "+"
                                                : ""}
                                            {Math.round(
                                                ((correctResponse.followupPSMAVolume -
                                                        correctResponse.baselinePSMAVolume) /
                                                    correctResponse.baselinePSMAVolume) *
                                                100
                                            )}
                                            %
                                        </b>
                                        . This corresponds to the{" "}
                                        <b>{correctResponse.category}</b> category.
                                    </div>
                                )}
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>

            {/* Next button */}
            {currentQuestionAnswer !== undefined && (
                <div className="test-question-next-button">
                    {currentQuestion >= testPatients.length - 1 ? (
                        <Button
                            className="w-100 btn btn-blue btn-primary"
                            onClick={() => {
                                onShowResult();
                            }}
                        >
                            Show result
                        </Button>
                    ) : (
                        <Button
                            className="w-100 btn btn-blue btn-primary"
                            onClick={() => {
                                setCurrentQuestion(currentQuestion + 1);
                                setCurrentSUVMean(10);
                            }}
                        >
                            Next case
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

export default TestQuestion;

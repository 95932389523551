import { Col, Modal, Row, Image, Button } from "react-bootstrap";

import RecipOnlineFlyer from "../assets/RECIPOnline-flyer.png";

import "./RECIPOnlineFlyerModal.css";
import { useNavigate } from "react-router-dom";
import { useRECIPOnlineContext } from "./RECIPOnlineContext";

interface RECIPOnlineStudyFlyerModalProps {
  show: boolean;
  onHide: () => void;
}

export default function RECIPOnlineStudyFlyerModal({
  show,
  onHide,
}: RECIPOnlineStudyFlyerModalProps): React.ReactElement {
  const navigate = useNavigate();
  const { setStudyParticipant } = useRECIPOnlineContext();

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      className="RECIPOnlineStudyFlyerModal"
      centered={true}
    >
      <button
        className="btn-close RECIPOnlineStudyFlyerModal--close"
        onClick={onHide}
      ></button>
      <Row>
        <Col lg="5" className="RECIPOnlineStudyFlyerModal--image">
          <Image fluid src={RecipOnlineFlyer} />
        </Col>
        <Col
          lg="7"
          className="d-flex flex-column justify-content-center RECIPOnlineStudyFlyerModal--content"
        >
          <h1>Would you like to take part in the RECIPonline study?</h1>
          <p>
            Your contribution could evolve our collective scientific
            understanding of using RECIP criteria for evaluation of drug
            efficacy in patients with prostate cancer.
          </p>
          <Row className="RECIPOnlineStudyFlyerModal--content-buttons">
            <Col sm="10">
              <Button
                variant="dark"
                className="w-100 RECIPOnlineStudyFlyerModal--button btn-secondary"
                onClick={() => {
                  setStudyParticipant(true);
                  onHide();
                  navigate("/");
                }}
              >
                Yes, I want to take part in the RECIPonline study
              </Button>
            </Col>
            <Col sm="2">
              <Button
                variant="outline-light"
                className="w-100 RECIPOnlineStudyFlyerModal--button"
                onClick={() => {
                  setStudyParticipant(false);
                  onHide();
                  navigate("/signup");
                }}
              >
                No
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

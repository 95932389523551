import { useNavigate } from "react-router-dom";
import { useRECIPOnlineContext } from "./RECIPOnlineContext";
import useAuth from "./useAuth";

export default function useEducationalPlatformClick() {
  const navigate = useNavigate();
  const { studyParticipant } = useRECIPOnlineContext();
  const { setShowFlyer } = useRECIPOnlineContext();

  function onEducationalPlatformClick() {
    if (studyParticipant !== undefined) {
      navigate("/");
    } else {
      setShowFlyer(true);
    }
  }

  return onEducationalPlatformClick;
}

import React, { useEffect } from "react";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";

interface RECIPOnlineContextType {
  showFlyer: boolean;
  studyParticipant: boolean | undefined;
  consentGiven: boolean;
  setShowFlyer: (value: boolean) => void;
  setStudyParticipant: (value: boolean) => void;
  setConsentGiven: (value: boolean) => void;
}

interface RECIPOnlineContextProviderProps {
  children?: React.ReactNode;
}

const RECIPOnlineContext = React.createContext<RECIPOnlineContextType>({
  showFlyer: false,
  studyParticipant: false,
  consentGiven: false,
  setShowFlyer: () => {},
  setStudyParticipant: () => {},
  setConsentGiven: () => {},
});

export function RECIPOnlineContextProvider({
  children,
}: RECIPOnlineContextProviderProps) {
  const { isAuthenticated } = useAuth();

  // Reset the local storage to initial state on successful sign up
  React.useEffect(() => {
    if (isAuthenticated) {
      setShowFlyer(false);
      setStudyParticipant(false);
      setConsentGiven(false);
    }
  }, [isAuthenticated]);

  const [showFlyer, setShowFlyer__internal] = React.useState<boolean>(
    window.localStorage.getItem("RECIPOnline.showFlyer") === "true" || false,
  );
  const [studyParticipant, setStudyParticipant__internal] =
    React.useState<boolean>(
      window.localStorage.getItem("RECIPOnline.studyParticipant") === "true" ||
        false,
    );
  const [consentGiven, setConsentGiven__internal] = React.useState<boolean>(
    window.localStorage.getItem("RECIPOnline.consentGiven") === "true" || false,
  );

  function setShowFlyer(value: boolean) {
    window.localStorage.setItem("RECIPOnline.showFlyer", value.toString());
    setShowFlyer__internal(value);
  }

  function setStudyParticipant(value: boolean) {
    window.localStorage.setItem(
      "RECIPOnline.studyParticipant",
      value?.toString() ?? "undefined",
    );
    setStudyParticipant__internal(value);
  }

  function setConsentGiven(value: boolean) {
    window.localStorage.setItem("RECIPOnline.consentGiven", value.toString());
    setConsentGiven__internal(value);
  }

  return (
    <RECIPOnlineContext.Provider
      value={{
        showFlyer,
        studyParticipant,
        consentGiven,
        setShowFlyer,
        setStudyParticipant,
        setConsentGiven,
      }}
    >
      {children}
    </RECIPOnlineContext.Provider>
  );
}

export function useRECIPOnlineContext() {
  return React.useContext(RECIPOnlineContext);
}

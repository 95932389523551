import { Auth } from "aws-amplify";
import React from "react";
import * as AWS from "aws-sdk";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { PatientIDs } from "../testConfiguration";
import { AWSConfig } from "../AWSConfig";

export default function AdminConsolePage(): React.ReactElement {
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] =
    React.useState<AWS.CognitoIdentityServiceProvider.UsersListType>([]);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    try {
      // Retrieve AWS credentials
      const credentials = await Auth.currentCredentials();

      // Initialize the Cognito Identity Service Provider
      const cognitoISP = new AWS.CognitoIdentityServiceProvider({
        credentials,
        region: AWSConfig.region,
      });

      let newUsers: AWS.CognitoIdentityServiceProvider.UsersListType = [];
      let paginationToken: string | undefined = undefined;

      // Pagination loop
      do {
        const response: any = await cognitoISP
            .listUsers({
              UserPoolId: AWSConfig.userPoolId,
              PaginationToken: paginationToken, // Pass the token here
            })
            .promise();

        const { Users = [], PaginationToken } = response;
        newUsers = newUsers.concat(Users);
        paginationToken = PaginationToken;
      } while (paginationToken != null);

      setUsers(newUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  }

  async function exportUsersToExcel() {
    const workbook = new Excel.Workbook();

    const usersWorksheet = workbook.addWorksheet("Users");

    const answerColumns = PatientIDs.map((patientID, index) => ({
      header: patientID.toString(),
      key: `custom:answers_${index}`,
    }));

    usersWorksheet.columns = [
      {
        header: "ID",
        key: "sub",
      },
      { header: "Country", key: "custom:country" },
      { header: "Medical field", key: "custom:specialty" },
      { header: "Position", key: "custom:career_level" },
      {
        header: "Experience in PET imaging (years)",
        key: "custom:pet_experience",
      },
      {
        header: "Experience in PSMA PET imaging (years)",
        key: "custom:psma_pet_experience",
      },
      {
        header: "Confidence before training",
        key: "custom:confidence_before",
      },
      { header: "Reason for using the platform", key: "custom:reason" },
      { header: "Platform rating", key: "custom:platform_rating" },
      {
        header: "Test 1: Confidence after training",
        key: "custom:confidence_after_v2_0",
      },
      {
        header: "Test 1: Favourite PET intensity",
        key: "custom:fav_pet_intensity_v2_0",
      },
      {
        header: "Test 1: Feedback",
        key: "custom:feedback_v2_0",
      },
      {
        header: "Test 2: Confidence after training",
        key: "custom:confidence_after_v2_1",
      },
      {
        header: "Test 2: Favourite PET intensity",
        key: "custom:fav_pet_intensity_v2_1",
      },
      {
        header: "Test 2: Feedback",
        key: "custom:feedback_v2_1",
      },
      {
        header: "Test 3: Confidence after training",
        key: "custom:confidence_after_v2_2",
      },
      {
        header: "Test 3: Favourite PET intensity",
        key: "custom:fav_pet_intensity_v2_2",
      },
      {
        header: "Test 3: Feedback",
        key: "custom:feedback_v2_2",
      },
      {
        header: "Test 4: Confidence after training",
        key: "custom:confidence_after_v2_3",
      },
      {
        header: "Test 4: Favourite PET intensity",
        key: "custom:fav_pet_intensity_v2_3",
      },
      {
        header: "Test 4: Feedback",
        key: "custom:feedback_v2_3",
      },
      {
        header: "Test 5: Confidence after training",
        key: "custom:confidence_after_v2_4",
      },
      {
        header: "Test 5: Favourite PET intensity",
        key: "custom:fav_pet_intensity_v2_4",
      },
      {
        header: "Test 5: Feedback",
        key: "custom:feedback_v2_4",
      },
      {
        header: "Answers",
      },
      ...answerColumns,
    ];

    for (let user of users) {
      const userAttributes = user.Attributes!.reduce(
        (prev, attr) => ({
          ...prev,
          [attr.Name]: attr.Value,
        }),
        {} as {
          [key: string]: string | number | undefined;
        }
      );

      // Split test-specific variables
      const answers =
        (userAttributes["custom:answers"] as string | undefined)?.split("") ||
        [];
      answers.forEach((answer, i) => {
        userAttributes[`custom:answers_${i}`] = answer;
      });
      const postTrainingConfidence =
        (
          userAttributes["custom:confidence_after"] as string | undefined
        )?.split(".") || [];
      postTrainingConfidence.forEach((confidence, i) => {
        userAttributes[`custom:confidence_after_v2_${i}`] = confidence;
      });
      const favouritePetIntensity =
        (
          userAttributes["custom:fav_pet_intensity"] as string | undefined
        )?.split(".") || [];
      favouritePetIntensity.forEach((intensity, i) => {
        userAttributes[`custom:fav_pet_intensity_v2_${i}`] = intensity;
      });
      const feedback =
        (userAttributes["custom:feedback"] as string | undefined)?.split(
          "\u241e"
        ) || [];
      feedback.forEach((f, i) => {
        userAttributes[`custom:feedback_v2_${i}`] = f;
      });

      usersWorksheet.addRow(userAttributes);
    }

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    saveAs(blob, "Patient Scans.xlsx");
  }

  return (
    <Container fluid="lg" style={{ minHeight: "82vh" }}>
      <Row className="mt-5">
        <Col>
          <p className="display-6">Admin Console</p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex flex-direction-column align-items-baseline justify-content-between">
          <h4>Users ({users.length})</h4>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => {
              exportUsersToExcel();
            }}
          >
            Export to Excel
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Email</th>
            <th>Country</th>
            <th>Study participant</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) => (
            <tr key={i}>
              <td>
                {user.Attributes?.find((attr) => attr.Name === "email")?.Value}
              </td>
              <td>
                {
                  user.Attributes?.find(
                    (attr) => attr.Name === "custom:country"
                  )?.Value
                }
              </td>
              <td>
                {user.Attributes?.find(
                  (attr) => attr.Name === "custom:study_participant"
                )?.Value
                  ? "Yes"
                  : "No"}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loading && (
        <Row>
          <Col className="d-flex justify-content-center">
            <Spinner
              as="span"
              className="m-5"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

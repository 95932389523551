import React from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import {
  PatientIDs,
  ResponseCategory,
  TestPatientAssignment,
  TestResponses,
} from "../testConfiguration";
import { Auth } from "aws-amplify";
import PostStudyQuestionnaire from "./PostStudyQuestionnaire";

import "./TestResults.css";
import { Button } from "react-bootstrap";

type TestResultsProps = {
  testId: number;
  currentTestAnswers: Array<ResponseCategory | undefined>;
};

function TestResults({
  testId,
  currentTestAnswers,
}: TestResultsProps): React.ReactElement {
  const [
    shouldShowPostStudyQuestionnaire,
    setShouldShowPostStudyQuestionnaire,
  ] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    fetchUserAttributes();
  }, []);

  async function fetchUserAttributes() {
    const user = await Auth.currentAuthenticatedUser();
    setShouldShowPostStudyQuestionnaire(
      user.attributes.hasOwnProperty("custom:study_participant"),
    );
  }

  const testPatients = TestPatientAssignment.get(testId);
  if (!testPatients) {
    console.error("Test definition not found");
    redirect("/");
    return <></>;
  }

  let correctAnswers = 0;
  currentTestAnswers.forEach((answer, i) => {
    const patientIndex = testPatients[i];
    const patientId = PatientIDs[patientIndex];
    const correctAnswer = TestResponses.get(patientId);
    if (answer === correctAnswer?.category) {
      correctAnswers += 1;
    }
  });

  return (
    <div className="test-results-container">
      <div className="test-results-header">
        <h5>Test {testId}</h5>
        <h4>Results</h4>
      </div>
      <div className="test-results-content">
        <p>Congratulations! You have completed test {testId}!</p>
        <p>
          You have correctly answered {correctAnswers} out of{" "}
          {testPatients.length} questions.
        </p>
      </div>
      {shouldShowPostStudyQuestionnaire && (
        <div className="test-results-questionnaire">
          <PostStudyQuestionnaire
            testId={testId}
            onSubmit={() => {
              navigate("/");
            }}
          />
        </div>
      )}
      <div className="test-results-actions">
        <Link to="/">
          <Button className="btn-blue">Try more tests</Button>
        </Link>
      </div>
    </div>
  );
}

export default TestResults;

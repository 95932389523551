import { Col, Container, Row, Card } from "react-bootstrap";
import "./TermsAndConditions.css";

export default function TermsAndConditions(): React.ReactElement {
  return (
      <Container fluid="lg" className="terms-container my-5">
        {/* Introduction Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h3 className="mb-3">Terms and Conditions</h3>
              <p className="text-muted">
                Effective date: April 21, 2023
                <br />
                Please read these terms and conditions carefully before using the
                RECIP Educational Platform.
              </p>
            </Col>
          </Row>
        </Card>

        {/* Interpretation and Definitions Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">Interpretation and Definitions</h4>
              <h5 className="mt-3">Interpretation</h5>
              <p className="text-muted">
                The words of which the initial letter is capitalized have meanings
                defined under the following conditions. The following definitions
                shall have the same meaning regardless of whether they appear in
                singular or in plural.
              </p>

              <h5 className="mt-4">Definitions</h5>
              <ul className="text-muted">
                <li>
                  <b>Affiliate</b> means an entity that controls, is controlled by
                  or is under common control with a party.
                </li>
                <li>
                  <b>Account</b> means a unique account created for You to access
                  our Platform.
                </li>
                <li>
                  <b>Country</b> refers to: Maryland, United States.
                </li>
                <li>
                  <b>Website</b> refers to RECIP Educational Platform.
                </li>
                <li>
                  <b>Device</b> means any device that can access the Platform.
                </li>
                <li>
                  <b>Platform</b> refers to the RECIP Educational Platform.
                </li>
                <li>
                  <b>Terms and Conditions</b> mean these Terms and Conditions that
                  form the entire agreement.
                </li>
                <li>
                  <b>You</b> means the individual accessing or using the Platform.
                </li>
              </ul>
            </Col>
          </Row>
        </Card>

        {/* Acknowledgement Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">Acknowledgement</h4>
              <p className="text-muted">
                These are the Terms and Conditions governing the use of this
                Platform and the agreement that operates between You and the
                Owner. By accessing or using the Platform, You agree to be bound
                by these Terms and Conditions. You represent that you are over the
                age of 18.
              </p>
            </Col>
          </Row>
        </Card>

        {/* User Accounts Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">User Accounts</h4>
              <p className="text-muted">
                When You create an account with Us, You must provide Us
                information that is accurate and complete at all times. You are
                responsible for safeguarding the password and any activities or
                actions under Your password.
              </p>
            </Col>
          </Row>
        </Card>

        {/* Links to Other Websites Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">Links to Other Websites</h4>
              <p className="text-muted">
                Our Platform may contain links to third-party websites. We assume
                no responsibility for the content, privacy policies, or practices
                of any third-party websites or services.
              </p>
            </Col>
          </Row>
        </Card>

        {/* Termination Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">Termination</h4>
              <p className="text-muted">
                We may terminate or suspend Your account immediately, without
                prior notice or liability, for any reason, including if You breach
                these Terms and Conditions.
              </p>
            </Col>
          </Row>
        </Card>

        {/* Limitation of Liability Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">Limitation of Liability</h4>
              <p className="text-muted">
                In no event shall the Owner or its suppliers be liable for any
                special, incidental, indirect, or consequential damages
                whatsoever.
              </p>
            </Col>
          </Row>
        </Card>

        {/* "AS IS" and "AS AVAILABLE" Disclaimer */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">"AS IS" and "AS AVAILABLE" Disclaimer</h4>
              <p className="text-muted">
                The Platform is provided to You "AS IS" and "AS AVAILABLE" without
                warranty of any kind.
              </p>
            </Col>
          </Row>
        </Card>

        {/* Governing Law Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">Governing Law</h4>
              <p className="text-muted">
                The laws of the Country, excluding its conflicts of law rules,
                shall govern these Terms.
              </p>
            </Col>
          </Row>
        </Card>

        {/* Disputes Resolution */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">Disputes Resolution</h4>
              <p className="text-muted">
                If You have any concerns or disputes about the Platform, You agree
                to first try to resolve the dispute informally by contacting the
                Owner.
              </p>
            </Col>
          </Row>
        </Card>

        {/* Contact Us Section */}
        <Card className="p-4 mb-4 shadow-sm terms-card">
          <Row>
            <Col>
              <h4 className="mb-3">Contact Us</h4>
              <p className="text-muted">
                If you have any questions about these Terms and Conditions, You
                can contact us by email: agafita1@jhmi.edu.
              </p>
            </Col>
          </Row>
        </Card>
      </Container>
  );
}

import React from "react";
import {
  NumberOfPatients,
  PatientIDs,
  ResponseCategory,
  TestPatientAssignment,
  TestResponses,
  deserialiseResponseCategory,
  serialiseResponseCategory,
} from "../testConfiguration";
import { Auth } from "aws-amplify";
import useAuth from "./useAuth";

type TestAnswersContextType = {
  answers: Array<ResponseCategory | undefined>;
  correctAnswersForTestId: (testId: number) => number;
  uploadTestAnswers: (
    testId: number,
    testAnswers: Array<ResponseCategory | undefined>,
  ) => Promise<void>;
};

const TestAnswersContext = React.createContext<TestAnswersContextType>({
  answers: new Array(NumberOfPatients).fill(undefined),
  correctAnswersForTestId: () => 0,
  uploadTestAnswers: () => {
    return Promise.resolve();
  },
});

interface TestAnswersContextProviderProps {
  children?: React.ReactNode;
}

export function TestAnswersContextProvider({
  children,
}: TestAnswersContextProviderProps) {
  const [answers, setAnswers] = React.useState<
    Array<ResponseCategory | undefined>
  >(new Array(NumberOfPatients).fill(undefined));
  const { isAuthenticated } = useAuth();

  async function fetchAnswers() {
    const user = await Auth.currentAuthenticatedUser();

    const newAnswers = user.attributes["custom:answers"];

    if (newAnswers) {
      const newAnswersArray = newAnswers
        .split("")
        .map(deserialiseResponseCategory);

      // If new patients have been added since the last update, we need to append zeroes
      if (newAnswersArray.length < NumberOfPatients) {
        newAnswersArray.concat(
          new Array(NumberOfPatients - newAnswersArray.length).fill(undefined),
        );
      }

      setAnswers(newAnswersArray);
    }
  }

  function correctAnswersForTestId(testId: number): number {
    const testPatients = TestPatientAssignment.get(testId);
    if (!testPatients) {
      console.warn("Test definition not found");
      return 0;
    }

    let correctAnswers = 0;
    // Calculate the number of correct results
    for (let patientIndex of testPatients) {
      const patientId = PatientIDs[patientIndex];
      const correctAnswer = TestResponses.get(patientId);
      if (answers[patientIndex] === correctAnswer?.category) {
        correctAnswers += 1;
      }
    }
    return correctAnswers;
  }

  async function uploadTestAnswers(
    testId: number,
    testAnswers: Array<ResponseCategory | undefined>,
  ) {
    const testPatients = TestPatientAssignment.get(testId);

    if (!testPatients) {
      console.warn("Test ID not found");
      return;
    }

    const newAnswers = Array.from(answers);

    testAnswers.forEach((answer, i) => {
      const patientIndex = testPatients[i];
      newAnswers[patientIndex] = answer;
    });
    setAnswers(newAnswers);

    const serialisedAnswers = newAnswers
      .map(serialiseResponseCategory)
      .join("");
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:answers": serialisedAnswers,
    });
  }

  // Fetch answers on first render
  React.useEffect(() => {
    if (isAuthenticated) fetchAnswers();
  }, [isAuthenticated]);

  return (
    <TestAnswersContext.Provider
      value={{
        answers,
        correctAnswersForTestId,
        uploadTestAnswers,
      }}
    >
      {children}
    </TestAnswersContext.Provider>
  );
}

export function useTestAnswersContext() {
  return React.useContext(TestAnswersContext);
}

import { Container, Image } from "react-bootstrap";
import RadiologyLogo from "../assets/Radiology-logo.png";

export default function RadiologyFooter() {
  return (
      <Container fluid className="radiology-footer-container">
        <Container fluid="md">
          <div className="radiology-footer-content d-flex align-items-center justify-content-center flex-column flex-md-row gap-3 py-3">
            <Image
                fluid
                className="radiology-logo"
                src={RadiologyLogo}
                alt="Radiology Journal logo"
            />
            <div className="vr mx-2 d-none d-md-block"></div>
            <div className="text-center text-md-left">
              <small className="text-muted">
                Gafita A, Djaileb L, Rauscher I, et al. Response Evaluation
                Criteria in PSMA PET/CT (RECIP 1.0) in Metastatic
                Castration-resistant Prostate Cancer.
              </small>
              <br />
              <small className="text-muted">
                Radiology. 2023. DOI: 10.1148/radiol.222148.{" "}
                <a
                    href="https://pubs.rsna.org/doi/10.1148/radiol.222148"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="radiology-footer-link"
                >
                  Read article
                </a>
              </small>
            </div>
          </div>
        </Container>
      </Container>
  );
}
